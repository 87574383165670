<template>
    <transition name="slide-up">
        <div v-show="show" class="sheet">
            <div class="title">
                <span>매칭 프로그램 선택</span>
                <img :src="require('@/assets/images/icons/close.png')" @click="close" alt="" />
            </div>
            <div class="items m-b-12">
                <div
                    v-for="item in products"
                    :key="item.id"
                    class="item"
                    :class="{ selected: item.id === selected, 'not-for-sale': notForSale(item) }"
                    @click="selectProduct(item)"
                >
                    <div class="left">
                        <span v-text="item.name" class="product-title" />
                        <!-- <span v-text="getProductFullName(item)" class="product-title" /> -->
                        <span
                            class="spoqa-f-bold product-price"
                            v-text="`${plan === 'unlimited' ? '월' : '회당'} ${item.discount.toLocaleString()}원`"
                        />
                    </div>
                    <div class="right">
                        <span v-if="notForSale(item)" class="not-for-sale-btn">선택불가</span>
                        <img
                            v-else
                            :src="require(`@/assets/images/icons/radio_${selected === item.id ? 'on' : 'off'}.png`)"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <BottomButton :non-fixed="true" label="가입하기" @click="submit" />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalSelectPlan',
    props: ['options'],
    data: () => ({
        show: false,
        selected: null,
    }),
    computed: {
        products() {
            return this.$store.getters.products[this.plan].filter(p => p.id < 17 && p.id > 10)
        },
        plan() {
            return this.options.category
        },
        me() {
            return this.$store.getters.me
        },
        notForSale() {
            return item => {
                if (item.code.includes('newuser') && this.me.rate_plan_history) {
                    return true
                } else {
                    return false
                }
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.show = true
        }, 200)
    },
    methods: {
        close(handler) {
            this.show = false
            setTimeout(() => this.$emit('close'), 200)
        },
        selectProduct(item) {
            if (!this.notForSale(item)) {
                this.selected = item.id
            }
        },
        submit() {
            const item = this.products.find(p => p.id === this.selected)
            this.$emit('close', item)
        },
        getProductFullName(item) {
            if (this.plan === 'unlimited') {
                return `${this.$translate(this.plan.toUpperCase())} - ${
                    item.valid_week / 4 > 6 ? `6+3개월` : `${item.valid_week / 4}개월`
                }`
            }
            return `${this.$translate(this.plan.toUpperCase())} - ${item.amount > 6 ? `6+3회` : `${item.amount}회`}`
        },
    },
}
</script>

<style lang="scss" scoped>
.sheet {
    width: 100vw;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px 12px 0 0 !important;

    .title {
        @include between;
        @include spoqa-f-bold;
        font-size: 18px;
        margin-bottom: 16px;

        img {
            width: 15px;
            height: 15px;
        }
    }
    .item {
        @include between;
        padding: 12px;
        margin-bottom: 8px;
        border-radius: 8px;
        border: 1px solid $grey-03;
        font-size: 14px;
        line-height: 24px;
        color: #111111;

        .left {
            // @include center;
            display: flex;
            flex-direction: column;
            .product-title {
                font-weight: 400;
            }
            .product-price {
                font-weight: 700;
            }
        }

        img {
            width: 32px;
            height: 32px;
        }
        .not-for-sale-btn {
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            color: #b9bdc1;
        }

        &.not-for-sale {
            background: #F0F8FF;
            color: #b9bdc1;
        }
    }
}
</style>
